import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Menambahkan ikon default untuk marker
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const GPSMap = () => {
  const locations = [
    { name: "Serpong 1", lat: -6.9680808, lng: 107.6262785 },
    { name: "Serpong 2", lat: -6.973022, lng: 107.6297014 },
    { name: "Serpong 3", lat: -6.975507, lng: 107.6296719 },
    { name: "Serpong 4", lat: -6.971500, lng: 107.628500 }, // Lokasi baru GSG
    { name: "Serpong 5", lat: -6.970200, lng: 107.627800 }, // Lokasi baru MSU
  ];

  return (
    <div>
      <h2>Air Monitoring Stations Map</h2>
      <MapContainer
        center={[-6.973022, 107.6297014]}
        zoom={14}
        style={{ height: "350px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
      <div style={{ marginTop: "20px" }}>
        <p>Stasiun: Serpong 1, Serpong 2, Serpong 3, Serpong 4, Serpong 5</p>
      </div>
    </div>
  );
};

export default GPSMap;
