import React, { useState } from "react";
import {
  Container, Box, Typography, Button, TextField, Select, MenuItem,
  FormControl, InputLabel, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Backdrop, CircularProgress
} from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import merah from "../../imgAVIF/merah.avif";
import kuning from "../../imgAVIF/kuning.avif";
import hijau from "../../imgAVIF/Hijau.avif";

const flagIcons = { merah, kuning, hijau };
const convertAVIFtoBase64 = (avifUrl, callback) => {
  const img = new Image();
  img.src = avifUrl;
  img.crossOrigin = "Anonymous";
  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    callback(canvas.toDataURL("image/png")); // Bisa juga pakai "image/jpeg"
  };
};
const DataHistoryFlagging = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [downloadFormat, setDownloadFormat] = useState("pdf");
  const [selectedStation, setSelectedStation] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchDataFromAPI = async () => {
    const stations = selectedStation === "All" ? ["GKU", "TULT", "Deli"] : [selectedStation];
    setOpen(true);

    try {
      const updatedDataPromises = stations.map(async (station) => {
        const response = await fetch(
          `https://biru-langit.com/api/Flagging/${station}?time_low=${startDate.toISOString()}&time_high=${endDate.toISOString()}`
        );
        const { data } = await response.json();

        return data.map((item, index) => ({
          id: `${station}-${index + 1}`,
          station,
          lastActive: item["Start Check"] || "N/A",
          lastOff: item["End Check"] || "N/A",
          flagNotif: item["Flag Notif"],
          flagIcon:
            item["Flag Notif"] === 0 ? flagIcons.hijau :
              item["Flag Notif"] === 1 ? flagIcons.kuning : flagIcons.merah,
          keterangan: item["Keterangan"] || "No description available",
          parameter: station,
        }));
      });

      const allData = (await Promise.all(updatedDataPromises)).flat();
      const sortedData = allData.sort((a, b) => new Date(a.lastActive) - new Date(b.lastActive));
      const updatedDataWithNo = sortedData.map((item, index) => ({
        ...item,
        no: index + 1,
      }));

      setFilteredData(updatedDataWithNo);
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData([]);
    }
    setOpen(false);
  };

  const downloadPDF = async () => {
    const doc = new jsPDF();

    const tableColumn = ["No", "Station", "Last Active", "Last Off", "Flag Icon", "Keterangan"]; // Hapus "Parameters"
    const tableRows = filteredData.map((row) => [
      row.no, row.station, row.lastActive, row.lastOff, "", row.keterangan // Hapus kolom "Parameters"
    ]);

    // Konversi semua ikon flag ke base64 sebelum membuat tabel
    const base64Flags = await Promise.all(
      filteredData.map((row) =>
        new Promise((resolve) => {
          const avifSrc = row.flagNotif === 0 ? flagIcons.hijau
            : row.flagNotif === 1 ? flagIcons.kuning
              : flagIcons.merah;
          convertAVIFtoBase64(avifSrc, resolve);
        })
      )
    );

    // Buat tabel
    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20, // Mulai dari Y=20 agar tidak kena header
      didDrawCell: (data) => {
        if (data.column.index === 4 && data.row.section === 'body') { // Kolom "Flag Icon", hanya di body
          const rowIndex = data.row.index;
          const base64Img = base64Flags[rowIndex];

          if (base64Img) {
            // Geser ke kanan (+5) dan turun sedikit (+3) biar tidak menutupi judul
            doc.addImage(base64Img, "PNG", data.cell.x + 1, data.cell.y + 3, 10, 10);
          }
        }
      },
    });

    doc.save("data-history-flagging.pdf");
  };

  const downloadExcel = () => {
    // Ubah flagIcon menjadi teks untuk download Excel
    const formattedData = filteredData.map(({ no, station, lastActive, lastOff, flagNotif, keterangan }) => ({
      no,
      station,
      lastActive,
      lastOff,
      flagIcon: flagNotif === 0 ? "Flag Hijau" : flagNotif === 1 ? "Flag Kuning" : " Flag Merah", // ubah menjadi teks
      keterangan
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data History Flagging");
  
    // Menyusun ulang header sesuai urutan yang benar
    XLSX.utils.sheet_add_aoa(worksheet, [["No", "Station", "Last Active", "Last Off", "Flag Icon", "Keterangan"]], { origin: "A1" });
  
    XLSX.writeFile(workbook, "data-history-flagging.xlsx");
  };
  

  return (
    <Container>
      <Box mb={3}>
        <Typography variant="h1" fontWeight="semi-bold">Data History Flagging</Typography>
        <Typography variant="subtitle1">Data History of Flagging System</Typography>
      </Box>

      <Box mb={2} display="flex" gap="10px">
        <TextField
          label="Start Date" type="date"
          value={startDate.toISOString().split("T")[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }} sx={{ width: "20%" }}
        />
        <TextField
          label="End Date" type="date"
          value={endDate.toISOString().split("T")[0]}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }} sx={{ width: "20%" }}
        />

        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Station</InputLabel>
          <Select
            value={selectedStation}
            onChange={(e) => setSelectedStation(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="TULT">TULT</MenuItem>
            <MenuItem value="GKU">GKU</MenuItem>
            <MenuItem value="Deli">Deli</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Download Format</InputLabel>
          <Select
            value={downloadFormat}
            onChange={(e) => setDownloadFormat(e.target.value)}
          >
            <MenuItem value="pdf">PDF</MenuItem>
            <MenuItem value="excel">Excel</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box p="10px" bgcolor="#D6E6F2" overflow="auto">
        <Typography variant="subtitle2" mb={1}>Total entries: {filteredData.length}</Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table id="data-table">
            <TableHead>
              <TableRow>
                {["No", "Station", "Last Active", "Last Off", "Flag Icon", "Keterangan"].map((header) => (
                  <TableCell key={header} sx={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.no}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.station}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.lastActive}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.lastOff}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>
                    <img src={row.flagIcon} alt={`Flag ${row.flagNotif}`} width="30" height="30" />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.keterangan}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} display="flex" gap="10px">
        <Button variant="contained" onClick={fetchDataFromAPI}>Apply Filter</Button>
        <Button
          variant="outlined"
          onClick={() => downloadFormat === "pdf" ? downloadPDF() : downloadExcel()}
        >
          Download {downloadFormat.toUpperCase()}
        </Button>
      </Box>

      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DataHistoryFlagging;
