import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { tokens } from "../../Components/Dashboard/ThemeDashboard";
import { format } from "date-fns";
import Header from "../../Components/Dashboard/ComponentDashboard/Header";
import { LineChart } from "@mui/x-charts/LineChart";
import BarChart from "../../Components/Dashboard/ComponentDashboard/BarChart";
import ProgressCircle from "../../Components/Dashboard/ComponentDashboard/ProgressCircle";
import { useNavigate } from "react-router-dom";
import NotificationPanel from "../../Components/Dashboard/ComponentDashboard/NotificationPanel";
import { fetchFlaggingData } from "../../Components/Dashboard/ComponentDashboard/fetchNotifications";


const Dashboard = () => {
  const [selectedStation, setSelectedStation] = useState("TULT");
  const [concentrationData, setConcentrationData] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [performanceData, setPerformanceData] = useState(null);
  const [selectedpollutant, setSelectedpollutant] = useState("PM 2.5");
  const [equipmentStatus, setEquipmentStatus] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const stations = ["Deli", "GKU", "TULT", "Tamansari", "Cisanti", "BJB","Serpong1", "Serpong2", "Serpong3", "Serpong4", "Serpong5"]; // tambah stasiun
  const pollutant = ["PM 2.5", "CO2"];
  const [notifications, setNotifications] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchFlaggingData(
        () => { }, // Callback untuk sortedData (bisa diabaikan jika tidak perlu)
        setNotifications,
        (newNotifications) => console.log("New notifications:", newNotifications),
        {
          hijau: "greenIcon",
          kuning: "yellowIcon",
          merah: "redIcon",
        }
      );
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 5 * 60 * 1000); // Setiap 5 menit

    return () => clearInterval(interval);
  }, []);


  const fetchDataByStation = async (stations) => {
    setOpen(true);
    try {
      let DashboardAPI;
      let DashboardJson; // Deklarasi variabel hanya sekali di sini
  
      // Memilih API berdasarkan stasiun yang dipilih
      if (["Tamansari", "Cisanti", "BJB"].includes(stations)) {
        DashboardAPI = await fetch(`https://biru-langit.com/api/Dashboard/SW21/${stations}`);
      } else if (["Serpong1", "Serpong2", "Serpong3", "Serpong4", "Serpong5"].includes(stations)) {
        // Menggunakan endpoint baru untuk stasiun Serpong
        DashboardAPI = await fetch(`https://biru-langit.com/api/data/dashboard/${stations}`);
      } else {
        DashboardAPI = await fetch(`https://biru-langit.com/api/Dashboard/${stations}`);
      }
  
      if (!DashboardAPI.ok) {
        throw new Error("Failed to fetch availability data");
      }
  
      DashboardJson = await DashboardAPI.json();

      // AVAILABILITY
      const availabilityData = DashboardJson.availability;
      setAvailabilityData(availabilityData);

      // PERFORMANCE
      const performanceData = DashboardJson.performance;
      setPerformanceData(performanceData);

      // CONCENTRATION
      const concentrationData = DashboardJson.concentration;
      setConcentrationData(concentrationData);

      // COMPLIANCE
      const complianceData = DashboardJson.compliance;
      setComplianceData(complianceData);

      const flagData = DashboardJson.flag;

      const allFlags = [
        { station: "TULT", flag: parseFloat(flagData[0]["tult"]) },
        { station: "GKU", flag: parseFloat(flagData[0]["gku"]) },
        { station: "Deli", flag: parseFloat(flagData[0]["deli"]) },
        { station: "Tamansari", flag: parseFloat(flagData[0]["tamansari"]) },
        { station: "Cisanti", flag: parseFloat(flagData[0]["cisanti"]) },
        { station: "BJB", flag: parseFloat(flagData[0]["bjb"]) },

      ];

      const getStatus = (flag) => {
        if (flag === null) return "On";
        return flag === 0 ? "Off" : "On";
      };

      setEquipmentStatus(
        allFlags.map(({ station, flag }) => ({
          station,
          status: getStatus(flag),
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchDataByStation(selectedStation);

    // Polling data every 1 minutes
    const interval = setInterval(() => {
      fetchDataByStation(selectedStation);
    }, 1 * 60 * 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [selectedStation]); // Dependency array includes selectedStation

  const handleChangeStation = async (event) => {
    const station = event.target.value;
    setSelectedStation(station);
    await fetchDataByStation(station);
  };

  const handleChangepollutant = (event) => {
    const pollutant = event.target.value;
    setSelectedpollutant(pollutant);
  };

  return (
    <Box m="0px 10px 10px 10px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Monitoring System" />

        {/* Wrapper untuk icon notifikasi dan dropdown station */}
        <Box display="flex" alignItems="center" mt={1}>  {/* Menambahkan marginTop untuk pengaturan vertikal */}
          {/* Icon notifikasi di sebelah kiri */}
          <Box mt={2}>  {/* Menurunkan posisi ikon notifikasi sedikit */}
            <NotificationPanel notifications={notifications || []} />
          </Box>

          {/* Dropdown Station di sebelah kanan */}
          <Box ml={2}>
            <Typography variant="h6" sx={{ marginRight: 2 }}>
              Station:
            </Typography>
            <Select
              value={selectedStation}
              onChange={handleChangeStation}
              style={{ minWidth: 120, maxHeight: "30px" }}
              sx={{
                "& .MuiSelect-selectMenu": {
                  padding: "10px 14px", // Sesuaikan dengan ukuran yang diinginkan
                  fontSize: "5px", // Sesuaikan dengan ukuran yang diinginkan
                },
                "& .MuiInputBase-root": {
                  fontSize: "5px", // Sesuaikan dengan ukuran yang diinginkan
                },
              }}
            >
              {stations.map((station, index) => (
                <MenuItem key={index} value={station}>
                  {station}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>


      {/* grid ama chart ngab */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="10px"
      >
        {/* baris pertama ngab */}
        <Box
          borderRadius="15px"
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="15px"
        >
          <Typography
            variant="h3"
            fontWeight="550"
            align="center"
            display="flex"
          >
            Performance
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="150" performanceData={performanceData} />
          </Box>
        </Box>

        <Box
          borderRadius="15px"
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h3"
            fontWeight="550"
            sx={{ padding: "15px 30px 0 30px" }}
          >
            Availability
          </Typography>
          <Box height="290px" mt="-30px">
            <BarChart availabilityData={availabilityData} />
          </Box>
        </Box>

        <Box
          borderRadius="15px"
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box p="10px" bgcolor={colors.primary[400]} color={colors.grey[100]}>
            <Typography variant="h3" fontWeight="550" gutterBottom>
              Compliance Status
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> No</TableCell>
                    <TableCell align="center" size="small">
                      Date
                    </TableCell>
                    <TableCell align="center">CV</TableCell>
                    <TableCell align="center">SD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {complianceData.slice(-6).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {format(new Date(item.created_at), "yyyy-MM-dd")}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{
                          color:
                            item["compliance_cv"] === "C" ? "black" : "red",
                          fontSize: "12px",
                        }}
                      >
                        {item["compliance_cv"]}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{
                          color:
                            item["compliance_std"] === "C" ? "black" : "red",
                          fontSize: "12px",
                        }}
                      >
                        {item["compliance_std"]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {/* baris kedua ngab*/}
        <Box
          borderRadius="10px"
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        // overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom={`1px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h3" fontWeight="550">
              Equipment Status
            </Typography>
          </Box>

          <Box
            p="5px 10px"
            bgcolor={colors.primary[400]}
            color={colors.grey[100]}
          >
            <TableContainer
              component={Paper}
              style={{ maxHeight: 200 }} // Set the max height for scrolling
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Station</TableCell>
                    <TableCell align="center" size="small">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {equipmentStatus.map((equipment, i) => (
                    <TableRow key={i}>
                      <TableCell>{equipment.station}</TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{
                          color: equipment.status === "On" ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {equipment.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box
          borderRadius="15px"
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            // p="0 15px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: "1px 30px 0 30px" }}
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="550"
                color={colors.grey[100]}
              >
                Concentration
              </Typography>
            </Box>
          </Box>
          <Box height="270px" m="-25px 0 0 0">
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="left"
              marginRight={"10px"}
            >
              <Typography variant="h6" sx={{ marginRight: 2 }}>
                Pollutant:
              </Typography>
              <Select
                value={selectedpollutant}
                onChange={handleChangepollutant}
                style={{ minWidth: 100, maxHeight: "30px" }}
                sx={{
                  "& .MuiSelect-selectMenu": {
                    padding: "10px 14px",
                    fontSize: "5px",
                  },
                  "& .MuiInputBase-root": {
                    fontSize: "5px",
                  },
                }}
              >
                {pollutant.map((Pollutant, index) => (
                  <MenuItem key={index} value={Pollutant}>
                    {Pollutant}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <LineChart
              xAxis={[
                {
                  data: concentrationData.map((data) =>
                    new Date(data.created_at).getTime()
                  ),

                  valueFormatter: (value) => {
                    try {
                      return format(new Date(value), "HH:mm");
                    } catch (error) {
                      return "NaN";
                    }
                  },
                },
              ]}
              series={[
                {
                  data: concentrationData.map((data) => {
                    // Ambil data konsentrasi sesuai dengan polutan yang dipilih
                    let concentration;
                    if (selectedpollutant === "PM 2.5") {
                      concentration = parseFloat(data.pm25);
                    } else if (selectedpollutant === "CO2") {
                      concentration = parseFloat(data.co2);
                    }
                    return isNaN(concentration)
                      ? null
                      : concentration.toFixed(1);
                  }),
                  color: selectedpollutant === "PM 2.5" ? "#16425B" : "#FF5733", // Tentukan warna berdasarkan polutan yang dipilih
                  valueFormatter: (value) =>
                    value == null ? "NaN" : value.toString(),
                },
                // tambahkan series lainnya sesuai kebutuhan
              ]}
              height={220}
              margin={{ top: 50, bottom: 20, left: 70 }}
            />
          </Box>
        </Box>

        <Box
          borderRadius="15px"
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box p="10px" bgcolor={colors.primary[400]} color={colors.grey[100]}>
            <Typography variant="h3" fontWeight="550" gutterBottom>
              Wind speed & Direction
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> No</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">WS</TableCell>
                    <TableCell align="center">WD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {concentrationData.slice(-4).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {format(
                          new Date(item.created_at),
                          "yyyy-MM-dd HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {item.ws}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {item.wd}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
